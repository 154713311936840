import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppCloseButton from 'src/components/AppCloseButton/AppCloseButton';

interface Props {
  title: string;
  open: boolean;
  onClose: () => void;
  content: React.ReactNode;
}

const AppDialog = ({ title, open, onClose, content }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      disableScrollLock
    >
      <DialogTitle sx={{ paddingBottom: 0 }}>
        {title}
        <AppCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
};

export default AppDialog;
