import { api } from 'src/services/api.service';
import { phaseApi } from 'src/services/phase.service';
import { DraftProject, Project } from '../models/Project';

export const parseProject = (p: any): Project => ({
  ...p,
  startDate: p.startDate ? new Date(p.startDate) : undefined,
});

export const projectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProject: builder.query<Project, string>({
      query: (projectId) => `projects/${projectId}`,
      providesTags: () => ['Project'],
      transformResponse: (result) => parseProject(result),
    }),
    findProjects: builder.query<Project[], void>({
      query: () => 'projects',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Project' as const,
                id,
              })),
              { type: 'Project', id: 'LIST' },
            ]
          : [{ type: 'Project', id: 'LIST' }],
      transformResponse: (results: Project[]) => [...results.map(parseProject)],
    }),
    createProject: builder.mutation<Project, DraftProject>({
      query: (draftProject) => ({
        url: 'projects',
        method: 'POST',
        body: draftProject,
      }),
      invalidatesTags: [{ type: 'Project', id: 'LIST' }],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(phaseApi.util.invalidateTags([{ type: 'Phase', id: 'LIST' }]));
      },
    }),
    updateProject: builder.mutation<void, Project>({
      query: (project) => ({
        url: `projects/${project.id}`,
        method: 'PUT',
        body: project,
      }),
      invalidatesTags: (result, error, project) => [
        { type: 'Project', id: project.id },
      ],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(phaseApi.util.invalidateTags([{ type: 'Phase', id: 'LIST' }]));
      },
    }),
    deleteProject: builder.mutation<void, string>({
      query: (projectId) => ({
        url: `projects/${projectId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, projectId) => [
        { type: 'Project', id: projectId },
      ],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(phaseApi.util.invalidateTags([{ type: 'Phase', id: 'LIST' }]));
      },
    }),
  }),
});

export const {
  useGetProjectQuery,
  useFindProjectsQuery,
  useUpdateProjectMutation,
  useCreateProjectMutation,
  useDeleteProjectMutation,
} = projectApi;
