import { useMemo } from 'react';
import { UserRole } from 'shared/types/UserRole';
import { useAppSelector } from 'src/hooks/useStore';
import { useGetUserInfosQuery } from 'src/services/account.service';
import PlanningHourView from 'src/views/PlanningHourView/PlanningHourView';
import PlanningProjectView from 'src/views/PlanningProjectView/PlanningProjectView';
import ProjectsView from 'src/views/ProjectsView/ProjectsView';
import UsersView from 'src/views/UsersView/UsersView';

export const useAuthentication = () => {
  const { authUser } = useAppSelector((state) => state.auth);

  const { data: userInfos } = useGetUserInfosQuery(authUser?.userId!, {
    skip: !authUser?.userId,
  });

  const isAuthenticated = useMemo(() => !!authUser?.userId, [authUser]);

  const hasRole = useMemo(
    () => (userRole: UserRole) =>
      isAuthenticated && userInfos?.role === userRole,
    [userInfos, isAuthenticated]
  );

  const isAdmin = useMemo(() => hasRole('Admin') || hasRole('Root'), [hasRole]);

  const isManager = useMemo(() => hasRole('Manager'), [hasRole]);

  const availableRoutes = useMemo(() => {
    if (isAdmin) {
      return [
        {
          path: '/planning',
          label: 'Planning',
          key: 'planning_project_route',
          component: PlanningProjectView,
        },
        {
          path: '/gestion-des-heures',
          label: 'Gestion des heures',
          key: 'planning_hour_route',
          component: PlanningHourView,
        },
        {
          path: '/projets',
          label: 'Affaires',
          key: 'projects_route',
          component: ProjectsView,
        },
        {
          path: '/collaborateurs',
          label: 'Collaborateurs',
          key: 'users_route',
          component: UsersView,
        },
      ];
    }

    if (isManager) {
      return [
        {
          path: '/planning',
          label: 'Planning',
          key: 'planning_project_route',
          component: PlanningProjectView,
        },
        {
          path: '/gestion-des-heures',
          label: 'Gestion des heures',
          key: 'planning_hour_route',
          component: PlanningHourView,
        },
        {
          path: '/projets',
          label: 'Affaires',
          key: 'projects_route',
          component: ProjectsView,
        },
      ];
    }

    return [
      {
        path: '/projets',
        label: 'Affaires',
        key: 'projects_route',
        component: ProjectsView,
      },
    ];
  }, [isManager, isAdmin]);

  return {
    userId: authUser?.userId,
    userInfos,
    isAuthenticated,
    hasRole,
    isAdmin,
    isManager,
    availableRoutes,
  };
};
