import {
  GridGroupNode,
  GridLogicOperator,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { useCallback, useEffect, useLayoutEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';
import dataGridSlice, { DataGridName } from 'src/store/reducers/dataGridSlice';

export const useDataGrid = (name: DataGridName) => {
  const apiRef = useGridApiRef();

  const dispatch = useAppDispatch();
  const { changeExpandedRowKeys, changeQuickFilterValues } =
    dataGridSlice.actions;

  const { expandedRowKeys, quickFilterValues, filterItems } = useAppSelector(
    (state) => state.dataGrid[name]
  );

  const isGroupExpandedByDefault = (node: GridGroupNode) =>
    expandedRowKeys.includes(node.groupingKey as string);

  useEffect(() => {
    apiRef.current.subscribeEvent('rowExpansionChange', (node: any) => {
      dispatch(
        changeExpandedRowKeys({
          name,
          rowKey: node.groupingKey as string,
          expanded: node.childrenExpanded ?? false,
        })
      );
    });
  }, [apiRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveSnapshot = useCallback(() => {
    dispatch(
      changeQuickFilterValues({
        name,
        values:
          apiRef.current?.exportState().filter?.filterModel
            ?.quickFilterValues ?? [],
      })
    );
    dispatch(
      dataGridSlice.actions.changeFilterItems({
        name,
        items: apiRef.current?.exportState().filter?.filterModel?.items ?? [],
      })
    );
  }, [apiRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    // handle refresh and navigating away/refreshing
    window.addEventListener('beforeunload', saveSnapshot);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener('beforeunload', saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot]);

  const initialStateFilter = {
    filter: {
      filterModel: {
        items: filterItems ?? [],
        quickFilterValues,
        quickFilterLogicOperator: GridLogicOperator.Or,
      },
    },
  };

  return {
    apiRef,
    isGroupExpandedByDefault,
    initialStateFilter,
  };
};
