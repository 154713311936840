import { Phase, PhaseSort } from '../models/Phase';
import { parseISO } from 'date-fns';
import { Department } from 'shared/types/Department';
import { getURLQuery } from 'src/utils/fetchUtils';
import { PhaseKind } from 'shared/types/PhaseKind';
import { api } from 'src/services/api.service';
import { parseProject } from 'src/services/project.service';

const parsePhase = (p: any): Phase => ({
  ...p,
  startDate: p.startDate ? parseISO(p.startDate) : undefined,
  endDate: p.endDate ? parseISO(p.endDate) : undefined,
  project: parseProject(p.project),
});

const formatPhase = (p: Phase): Phase => ({
  ...p,
  initialAmount: p.initialAmount || 0,
  additionalAmount: p.additionalAmount || 0,
});

export const phaseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPhase: builder.query<Phase, string>({
      query: (phaseId) => `phases/${phaseId}`,
      providesTags: (result, error, phaseId) => [
        { type: 'Phase', id: phaseId },
      ],
      transformResponse: (result) => parsePhase(result),
    }),
    findPhases: builder.query<Phase[], FindPhasesOptions>({
      query: (opts) => `phases${getURLQuery(opts)}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Phase' as const,
                id,
              })),
              { type: 'Phase', id: 'LIST' },
            ]
          : [{ type: 'Phase', id: 'LIST' }],
      transformResponse: (results: Phase[]) => [
        ...results.map(parsePhase).sort(PhaseSort),
      ],
    }),
    createPhase: builder.mutation<
      Phase,
      { projectId: string; kind: PhaseKind }
    >({
      query: ({ projectId, kind }) => ({
        url: 'phases',
        method: 'POST',
        body: { projectId, kind },
      }),
      invalidatesTags: [{ type: 'Phase', id: 'LIST' }],
    }),
    updatePhase: builder.mutation<void, Phase>({
      query: (phase) => ({
        url: `phases/${phase.id}`,
        method: 'PUT',
        body: formatPhase(phase),
      }),
      transformErrorResponse(error) {
        return error.data;
      },
      invalidatesTags: (result, error, phase) => [
        { type: 'Phase', id: phase.id },
      ],
    }),
    updatePhaseUsers: builder.mutation<
      void,
      { phaseId: string; department: Department; userIds: string[] }
    >({
      query: ({ phaseId, ...body }) => ({
        url: `phases/${phaseId}/users`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { phaseId }) => [
        { type: 'Phase', id: phaseId },
      ],
    }),
    notifyCompletion: builder.mutation<void, string>({
      query: (phaseId) => ({
        url: `phases/${phaseId}/notify-completion`,
        method: 'POST',
      }),
    }),
    deletePhase: builder.mutation<void, string>({
      query: (phaseId) => ({
        url: `phases/${phaseId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, phaseId) => [
        { type: 'Phase', id: phaseId },
      ],
    }),
  }),
});

export const {
  useCreatePhaseMutation,
  useDeletePhaseMutation,
  useFindPhasesQuery,
  useUpdatePhaseMutation,
  useUpdatePhaseUsersMutation,
  useGetPhaseQuery,
  useLazyGetPhaseQuery,
  useNotifyCompletionMutation,
} = phaseApi;
