import { Container } from '@mui/material';
import ProjectList from 'src/components/ProjectList/ProjectList';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';

const ProjectsView = () => {
  useDocumentTitle('Affaires');

  return (
    <Container maxWidth="xl" sx={{ marginTop: '3rem' }}>
      <ProjectList />
    </Container>
  );
};

export default ProjectsView;
