import React from 'react';
import { Box, Container } from '@mui/material';
import { useAuthentication } from 'src/hooks/useAuthentication';
import PlanningProjectList from 'src/views/PlanningProjectView/PlanningProjectList';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro';
import dataGridSlice, {
  PlanningDataGridState,
} from 'src/store/reducers/dataGridSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';
import { isValid } from 'date-fns';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';

const PlanningProjectView = () => {
  useDocumentTitle('Planning');
  const { isAdmin, isManager } = useAuthentication();
  const dispatch = useAppDispatch();

  const { startDate, endDate } = useAppSelector(
    (state) => state.dataGrid.planningProject as PlanningDataGridState
  );

  const [range, setRange] = React.useState<DateRange<Date> | undefined>(
    startDate && endDate ? [startDate, endDate] : undefined
  );

  const changeRange = (newRange: DateRange<Date>) => {
    setRange(newRange);
    if (newRange && newRange[0] && newRange[1]) {
      dispatch(
        dataGridSlice.actions.changePeriod({
          name: 'planningProject',
          startDate: newRange[0],
          endDate: newRange[1],
        })
      );
    }
  };

  if (!isAdmin && !isManager) {
    return <></>;
  }

  return (
    <Container maxWidth="xl" sx={{ marginTop: '3rem' }}>
      <Box
        sx={{ marginBottom: '1rem', justifyContent: 'center', display: 'flex' }}
      >
        <DateRangePicker
          value={range}
          onChange={changeRange}
          localeText={{ start: 'Date de début', end: 'Date de fin' }}
          sx={{ width: 'fit-content' }}
        />
      </Box>
      {range && isValid(range[0]) && isValid(range[1]) ? (
        <PlanningProjectList startDate={range[0]!} endDate={range[1]!} />
      ) : (
        <Box
          sx={{
            marginTop: '3rem',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          Veuillez sélectionner des dates de début et de fin
        </Box>
      )}
    </Container>
  );
};

export default PlanningProjectView;
