import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { getDay } from 'date-fns';
import React, { useState } from 'react';
import {
  DefaultPhaseKinds,
  PhaseKind,
  PhaseKinds,
} from 'shared/types/PhaseKind';
import { useForm } from 'src/hooks/useForm';
import { DraftProject } from 'src/models/Project';
import * as yup from 'yup';

export interface Props {
  onSubmit: (project: DraftProject) => void;
  open: boolean;
  onClose: () => void;
}

function AddProjectDialog({ onSubmit, onClose, open }: Props) {
  const [reference, setReference] = useState('');
  const [title, setTitle] = useState('');
  const [checked, setChecked] = React.useState<PhaseKind[]>(DefaultPhaseKinds);
  const [startDate, setStartDate] = useState<Date | null>(null);

  const schema = yup.object().shape({
    reference: yup.string().required('Veuillez renseigner le référence.'),
    title: yup.string().required('Veuillez renseigner le titre.'),
    phaseKinds: yup.array().min(1, 'Veuillez sélectionner au moins une phase.'),
    startDate: yup.date().typeError('Veuillez renseigner une date valide.'),
  });

  const { message, validate, messageType } = useForm(schema, {
    reference,
    title,
    phaseKinds: checked,
    startDate: startDate ?? undefined,
  });

  const handleTogglePhase = (value: PhaseKind) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const submitProject = async () => {
    await validate(() =>
      onSubmit({
        reference: reference!,
        title: title!,
        phaseKinds: checked,
        startDate: startDate ?? undefined,
        hidden: false,
      })
    );
  };

  return (
    <Box component="form" onSubmit={submitProject}>
      <Dialog open={open} scroll="paper" disableScrollLock fullWidth>
        <DialogTitle>Nouvelle affaire</DialogTitle>

        <DialogContent>
          <TextField
            value={reference}
            onChange={(event) => setReference(event.target.value)}
            autoFocus
            label="Référence"
            type="text"
            fullWidth
            variant="standard"
            margin="normal"
            error={messageType('reference') === 'error'}
          />
          <FormHelperText error>{message('reference')}</FormHelperText>
          <TextField
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            autoFocus
            label="Nom de l'affaire"
            type="text"
            fullWidth
            variant="standard"
            margin="normal"
            error={messageType('title') === 'error'}
          />
          <FormHelperText error>{message('title')}</FormHelperText>
          <FormControl component="fieldset" variant="outlined">
            <FormLabel
              component="label"
              sx={{ marginTop: '1rem' }}
              error={messageType('phaseKinds') === 'error'}
            >
              Phases
            </FormLabel>
            <FormGroup
              sx={{
                display: 'grid',
                columnGap: 3,
                rowGap: 1,
                gridTemplateColumns: 'repeat(3, 1fr)',
              }}
            >
              {PhaseKinds.map((phaseKind) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked.indexOf(phaseKind) !== -1}
                      onClick={handleTogglePhase(phaseKind)}
                    />
                  }
                  label={phaseKind}
                  key={phaseKind}
                />
              ))}
            </FormGroup>
          </FormControl>
          <FormHelperText error>{message('phaseKinds')}</FormHelperText>
          <FormControl component="fieldset" variant="outlined">
            <FormLabel component="label" sx={{ marginTop: '1rem' }}>
              Date de démarrage
            </FormLabel>
            <DatePicker
              value={startDate}
              onChange={(date) => setStartDate(date)}
              shouldDisableDate={(date) => getDay(date) !== 1}
            />
          </FormControl>
          <FormHelperText error>{message('startDate')}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
          <Button variant="contained" type="submit" onClick={submitProject}>
            Créer l’affaire
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddProjectDialog;
