import { Button, ButtonGroup, Container } from '@mui/material';
import { useAuthentication } from 'src/hooks/useAuthentication';

const HomeView = () => {
  const { availableRoutes, hasRole } = useAuthentication();

  return (
    <Container
      maxWidth="xl"
      sx={{
        marginTop: '10%',
        textAlign: 'center',
      }}
    >
      <ButtonGroup
        orientation="vertical"
        variant="text"
        color="primary"
        size="large"
      >
        {availableRoutes.map((route) => (
          <Button href={route.path} key={route.key}>
            {route.label}
          </Button>
        ))}
        <Button href="/mes-heures">Saisir mes heures</Button>
      </ButtonGroup>
    </Container>
  );
};

export default HomeView;
