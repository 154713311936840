import { Delete } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { getDay } from 'date-fns';
import { useState } from 'react';
import { useForm } from 'src/hooks/useForm';
import { Project } from 'src/models/Project';
import { useDeleteProjectMutation } from 'src/services/project.service';
import * as yup from 'yup';

export interface Props {
  project: Project;
  onSubmit: (project: Project) => void;
  open: boolean;
  onClose: () => void;
}

function ProjectDialog({ project, onSubmit, onClose, open }: Props) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [deleteProject] = useDeleteProjectMutation();

  const [reference, setReference] = useState(project?.reference);
  const [title, setTitle] = useState(project?.title);
  const [startDate, setStartDate] = useState(project?.startDate ?? null);
  const [hidden, setHidden] = useState(project?.hidden);

  const schema = yup.object().shape({
    reference: yup
      .string()
      .required()
      .required('Veuillez renseigner le référence.'),
    title: yup.string().required().required('Veuillez renseigner le titre.'),
    startDate: yup.date().typeError('Veuillez renseigner une date valide.'),
    hidden: yup.boolean(),
  });

  const { message, validate, messageType } = useForm(schema, {
    reference,
    title,
    startDate: startDate ?? undefined,
    hidden,
  });

  const submitProject = async () => {
    await validate(() =>
      onSubmit({
        ...project,
        reference: reference!,
        title: title!,
        startDate: startDate ?? undefined,
        hidden: hidden ?? false,
      })
    );
  };

  const submitDeleteProject = async () => {
    if (project) {
      await deleteProject(project?.id);
    }
    setDeleteDialogOpen(false);
    onClose();
  };

  return (
    <Box component="form" onSubmit={submitProject}>
      <Dialog open={open} scroll="paper" disableScrollLock fullWidth>
        <DialogTitle>
          Affaire {project.reference} - {project.title}
        </DialogTitle>

        <DialogContent>
          <TextField
            value={reference}
            onChange={(event) => setReference(event.target.value)}
            autoFocus
            label="Référence"
            type="text"
            fullWidth
            variant="standard"
            margin="normal"
            error={messageType('reference') === 'error'}
          />
          <FormHelperText error>{message('reference')}</FormHelperText>
          <TextField
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            autoFocus
            label="Nom de l'affaire"
            type="text"
            fullWidth
            variant="standard"
            margin="normal"
            error={messageType('title') === 'error'}
          />
          <FormHelperText error>{message('title')}</FormHelperText>
          <FormControl component="fieldset" variant="outlined">
            <FormLabel component="label" sx={{ marginTop: '1rem' }}>
              Date de démarrage
            </FormLabel>
            <DatePicker
              value={startDate}
              onChange={(date) => setStartDate(date)}
              shouldDisableDate={(date) => getDay(date) !== 1}
            />
          </FormControl>
          <FormHelperText error>{message('startDate')}</FormHelperText>
          <FormControlLabel
            control={
              <Checkbox
                checked={hidden}
                onChange={(event) => setHidden(event.target.checked)}
              />
            }
            label="Affaire masquée"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
          <Button variant="contained" type="submit" onClick={submitProject}>
            Enregistrer les modifications
          </Button>
        </DialogActions>
        {project && (
          <DialogActions>
            <Button
              color="error"
              startIcon={<Delete />}
              size="small"
              onClick={() => setDeleteDialogOpen(true)}
            >
              Supprimer l'affaire
            </Button>
            <Dialog
              open={deleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              color="warning"
            >
              <DialogTitle>Supprimer l'affaire</DialogTitle>
              <DialogContent>
                <Alert severity="warning">
                  Êtes-vous sûr de vouloir supprimer l'affaire  
                  {project.reference} - {project.title} ?
                </Alert>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setDeleteDialogOpen(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={submitDeleteProject}
                >
                  Supprimer
                </Button>
              </DialogActions>
            </Dialog>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
}

export default ProjectDialog;
