import { Box, Button, DialogActions, Link } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  getGridBooleanOperators,
  GridAggregationFunction,
  GridCellParams,
  GridColDef,
  GridFilterPanel,
  GridRowParams,
  GridRowsProp,
  GridToolbarFilterButton,
  GridValidRowModel,
  GRID_AGGREGATION_FUNCTIONS,
  GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { Department } from 'shared/types/Department';
import { isDefined } from 'shared/utils/utils';
import AppDataGrid from 'src/components/AppDataGrid/AppDataGrid';
import AppSnackbar from 'src/components/AppSnackbar/AppSnackbar';
import ExportToolbar from 'src/components/ExportToolbar/ExportToolbar';
import AddProjectButton from 'src/components/ProjectList/AddProjectButton';
import PhasesUsersEditCell from 'src/components/ProjectList/PhaseUsersEditCell';
import ProjectEditCell from 'src/components/ProjectList/ProjectEditCell';
import ProjectPhasesDialog from 'src/components/ProjectPhasesDialog/ProjectPhasesDialog';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { useDataGrid } from 'src/hooks/useDataGrid';
import { useAppDispatch } from 'src/hooks/useStore';
import { getDepartmentRate, Phase } from 'src/models/Phase';
import {
  useFindPhasesQuery,
  useNotifyCompletionMutation,
  useUpdatePhaseMutation,
} from 'src/services/phase.service';
import dataGridSlice from 'src/store/reducers/dataGridSlice';
import {
  currencyColDef,
  currencyFormatter,
  PhaseKindColDef,
  projectReferenceColDef,
  projectTitleColDef,
  rateColDef,
} from 'src/utils/columnUtils';

const ProjectList = () => {
  const dispatch = useAppDispatch();
  const { apiRef, isGroupExpandedByDefault, initialStateFilter } =
    useDataGrid('project');

  const { hasRole } = useAuthentication();
  const { data } = useFindPhasesQuery({
    includes: ['consumed'],
  });
  const phases = data ?? [];

  const [updatePhase, { isSuccess: isUpdateSuccess, error }] =
    useUpdatePhaseMutation();

  const [notifyCompletion, { isSuccess: isNotifySuccess }] =
    useNotifyCompletionMutation();

  const [achievedPhase, setAchievedPhase] = React.useState<string>();

  const canEdit = useMemo(
    () => hasRole('Admin') || hasRole('Manager'),
    [hasRole]
  );

  const getProject = (id: string) => {
    return phases.find((phase) => phase.project.id === id)?.project;
  };

  const processRowUpdate = async (
    updatedRow: GridValidRowModel,
    originalRow: GridValidRowModel
  ) => {
    const { projectId, projectReference, projectTitle, ...phase } = updatedRow;
    if (
      (phase as Phase).progressRate === 0 &&
      (originalRow as Phase).progressRate !== 0
    ) {
      setAchievedPhase((phase as Phase).id);
    }
    await updatePhase(phase as Phase);
    return updatedRow;
  };

  const startDateAgg: GridAggregationFunction<Date | null> = {
    apply: ({ values }) =>
      (values.filter((v) => v !== null && v !== undefined) as Date[]).sort(
        (a: Date, b: Date) => a.getTime() - b.getTime()
      )[0] ?? null,
    columnTypes: ['date'],
    label: '',
  };

  const rows: GridRowsProp = phases.map((phase) => ({
    ...phase,
    projectId: phase.project.id,
    projectReference: phase.project.reference,
    projectTitle: phase.project.title,
    isHidden: phase.project.hidden,
  }));

  const usersColumn = (
    field: string,
    department: Department,
    getUsers: (phase: Phase) => string[] | undefined
  ): GridColDef =>
    (canEdit
      ? {
          field,
          headerName: '',
          width: 60,
          align: 'center',
          disableColumnMenu: true,
          type: 'actions',
          sortable: false,
          getActions: ({ row }: GridRowParams<Phase>) =>
            getUsers(row) !== undefined
              ? [
                  <PhasesUsersEditCell
                    phaseId={row.id}
                    userIds={getUsers(row)!}
                    department={department}
                  />,
                ]
              : [],
        }
      : undefined) as GridColDef;

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...initialStateFilter,
      rowGrouping: {
        model: ['projectId'],
      },
      aggregation: {
        model: {
          consumedAmount: 'sum',
          initialAmount: 'sum',
          additionalAmount: 'sum',
          totalAmount: 'sum',
          remainedAmount: 'sum',
          startDate: 'startDateAgg',
          progressRate: 'remained',
          thermalRate: 'remainedThermal',
          electricityRate: 'remainedElectricity',
          structuralRate: 'remainedStructural',
          economyRate: 'remainedEconomy',
        },
      },
      pinnedColumns: {
        left: [
          'projectId',
          'actions',
          'projectReference',
          'projectTitle',
          GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
          'kind',
        ],
      },
      columns: {
        columnVisibilityModel: {
          isHidden: false,
        },
      },
    },
  });

  const columns: GridColDef[] = [
    canEdit
      ? {
          field: 'actions',
          type: 'actions',
          width: 1,
          cellClassName: 'actions',
          filterable: false,
          getActions: ({ id }: GridCellParams<Phase>) => {
            const projectId = id.toString().split('/')[1];
            return projectId ? [<ProjectEditCell projectId={projectId} />] : [];
          },
        }
      : undefined,
    {
      field: 'isHidden',
      headerName: 'Affaire masquée',
      filterable: true,
      groupable: false,
      hideable: true,
      filterOperators: getGridBooleanOperators(),
    },
    {
      ...projectReferenceColDef(true),
      renderCell: (params: GridCellParams<Phase>) =>
        params.rowNode.type === 'group'
          ? getProject(params.rowNode.groupingKey as string)?.reference
          : '',
    },
    {
      ...projectTitleColDef(true),
      renderCell: (params: GridCellParams<Phase>) =>
        params.rowNode.type === 'group'
          ? getProject(params.rowNode.groupingKey as string)?.title
          : '',
    },
    {
      field: 'projectId',
      filterable: false,
      headerName: '',
      width: 20,
      renderCell: () => <></>,
    },
    {
      ...PhaseKindColDef,
      width: 65,
      groupable: false,
      aggregable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams<Phase>) =>
        canEdit && params.rowNode.type === 'group' ? (
          <ProjectPhasesDialog
            projectId={getProject(params.rowNode.groupingKey as string)!.id}
          />
        ) : (
          params.value
        ),
    },
    {
      ...currencyColDef('consumedAmount', 'Consommé'),
      editable: false,
      cellClassName: (params: GridCellParams<Phase>) =>
        (params.row.consumedAmount ?? 0) >
        params.row.initialAmount + params.row.additionalAmount
          ? 'overconsumed'
          : '',
    },
    currencyColDef('initialAmount', 'Base contrat'),
    currencyColDef('additionalAmount', 'Avenants'),
    {
      ...currencyColDef('totalAmount', 'Base + avenants'),
      editable: false,
      valueGetter: (params: GridCellParams<Phase>) =>
        params.row.initialAmount + params.row.additionalAmount,
    },
    rateColDef('progressRate', 'Avancement'),
    {
      field: 'startDate',
      headerName: 'Démarrage',
      width: 130,
      type: 'date',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      editable: false,
      align: 'center',
      headerClassName: 'wrap',
      headerAlign: 'center',
    },
    {
      field: 'missionDuration',
      headerName: 'Durée de mission',
      width: 130,
      type: 'number',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      editable: canEdit,
      align: 'center',
      headerClassName: 'wrap',
    },
    {
      field: 'validationDuration',
      headerName: 'Durée de validation',
      width: 130,
      type: 'number',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      editable: canEdit,
      align: 'center',
      headerClassName: 'wrap',
    },
    {
      field: 'endDate',
      headerName: 'Fin de mission',
      width: 130,
      type: 'date',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      editable: false,
      align: 'center',
      headerClassName: 'wrap',
      headerAlign: 'center',
    },
    rateColDef('thermalRate', 'Thermique'),
    usersColumn('thermalUsers', 'THERMAL', (phase) => phase.thermalUsers),
    rateColDef('electricityRate', 'Electricité'),
    usersColumn(
      'electricityUsers',
      'ELECTRICITY',
      (phase) => phase.electricityUsers
    ),
    rateColDef('structuralRate', 'Structure'),
    usersColumn(
      'structuralUsers',
      'STRUCTURAL',
      (phase) => phase.structuralUsers
    ),
    rateColDef('economyRate', 'Economie'),
    usersColumn('economyUsers', 'ECONOMY', (phase) => phase.economyUsers),
  ]
    .filter(isDefined)
    .map((colDef) => ({
      ...colDef,
      editable: canEdit && colDef.editable,
    })) as GridColDef[];

  const remained = (
    department?: Department
  ): GridAggregationFunction<Phase, string> => ({
    label: 'à produire',
    getCellValue: ({ row }) => row as Phase,
    apply: ({ values }) => {
      let amount = 0;
      values.forEach((phase) => {
        if (phase) {
          const progressRate = phase?.progressRate ?? null;
          const rate = department
            ? getDepartmentRate(phase, department) ?? null
            : 100;
          if (
            phase.initialAmount !== null &&
            phase.additionalAmount !== null &&
            progressRate !== null &&
            rate !== null
          ) {
            amount +=
              ((phase.initialAmount + phase.additionalAmount) *
                progressRate *
                rate) /
              10000;
          }
        }
      });
      return currencyFormatter({ value: amount });
    },
    columnTypes: ['number'],
  });

  const toolbar = () => (
    <>
      <ExportToolbar
        additionalButtons={[
          canEdit ? <AddProjectButton key={'add_project_button'} /> : undefined,
          <GridToolbarFilterButton key={'filter_button'} />,
        ].filter(isDefined)}
        showQuickFilter={true}
      />
    </>
  );

  const filterPanel = () => (
    <GridFilterPanel disableAddFilterButton disableRemoveAllButton />
  );

  return (
    <>
      <AppSnackbar
        isOpen={isUpdateSuccess}
        severity="success"
        message="Modification enregistrée"
      />
      <AppSnackbar
        isOpen={isNotifySuccess}
        severity="success"
        message="Email envoyé"
      />
      {error !== undefined && (
        <AppSnackbar
          isOpen={true}
          severity="error"
          message={(error as Error).message}
          autoHideDuration={5000}
        />
      )}
      {achievedPhase && (
        <Dialog open={true} onClose={() => setAchievedPhase(undefined)}>
          <DialogTitle>Phase terminée</DialogTitle>
          <DialogContent>
            La phase est terminée, souhaitez-vous envoyer un mail à la
            comptabilité pour facturation ?
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setAchievedPhase(undefined)}
            >
              Non
            </Button>
            <Button
              variant="contained"
              onClick={async () => {
                await notifyCompletion(achievedPhase);
                setAchievedPhase(undefined);
              }}
            >
              Oui
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Box height="calc(100vh - 250px)">
        <AppDataGrid
          apiRef={apiRef}
          initialState={initialState}
          rows={rows}
          columns={columns}
          processRowUpdate={processRowUpdate}
          isGroupExpandedByDefault={isGroupExpandedByDefault}
          slots={{
            toolbar,
            filterPanel,
          }}
          defaultGroupingExpansionDepth={10}
          disableColumnFilter={false}
          groupingColDef={{
            hideDescendantCount: true,
            disableColumnMenu: true,
            resizable: false,
            sortable: false,
            aggregable: false,
            filterable: false,
            renderHeader: () => (
              <div style={{ flexDirection: 'column', lineHeight: '16px' }}>
                <Link
                  onClick={() => {
                    dispatch(dataGridSlice.actions.collapseAll('project'));
                  }}
                  sx={{ fontSize: '11px' }}
                >
                  Plier
                </Link>
                <br />
                <Link
                  onClick={() => {
                    dispatch(
                      dataGridSlice.actions.expandAll({
                        name: 'project',
                        allKeys: _.uniq(
                          phases.map((phase) => phase.project.id)
                        ),
                      })
                    );
                  }}
                  sx={{ fontSize: '11px' }}
                >
                  Déplier
                </Link>
              </div>
            ),
          }}
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
            startDateAgg,
            remained: remained(),
            remainedThermal: remained('THERMAL'),
            remainedElectricity: remained('ELECTRICITY'),
            remainedStructural: remained('STRUCTURAL'),
            remainedEconomy: remained('ECONOMY'),
          }}
          getRowClassName={(params) =>
            params.row.progressRate === 0 ? 'completed' : ''
          }
        />
      </Box>
    </>
  );
};

export default ProjectList;
