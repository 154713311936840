import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, FormHelperText } from '@mui/material';
import * as yup from 'yup';
import {
  passwordConfirmationValidator,
  passwordValidator,
  useForm,
} from 'src/hooks/useForm';
import { useResetPasswordMutation } from 'src/services/account.service';
import AppError from 'src/components/AppError/AppError';

interface Props {
  tokenId: string;
}

const AccountResetPasswordForm = ({ tokenId }: Props) => {
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const [resetPassword, { isError: isUpdateError }] = useResetPasswordMutation({
    fixedCacheKey: 'shared-reset-password',
  });

  const schema = yup.object().shape({
    newPassword: passwordValidator,
    newPasswordConfirm: passwordConfirmationValidator('newPassword'),
  });

  const { message, validate, messageType } = useForm(schema, {
    newPassword,
    newPasswordConfirm,
  });

  const submit = async () => {
    await validate(() => {
      resetPassword({
        tokenId,
        newPassword,
      });
    });
  };

  return (
    <Box component="form">
      <TextField
        value={newPassword}
        onChange={(event) => setNewPassword(event.target.value)}
        autoFocus
        label="Nouveau mot de passe"
        type="password"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('newPassword') === 'error'}
        required
      />
      <FormHelperText error>{message('newPassword')}</FormHelperText>
      <TextField
        value={newPasswordConfirm}
        onChange={(event) => setNewPasswordConfirm(event.target.value)}
        autoFocus
        label="Confirmation du nouveau mot de passe"
        type="password"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('newPasswordConfirm') === 'error'}
        required
      />
      <FormHelperText error>{message('newPasswordConfirm')}</FormHelperText>
      <AppError open={isUpdateError} />
      <Button
        variant="contained"
        onClick={submit}
        sx={{ marginY: '2rem' }}
        fullWidth
      >
        Enregistrer
      </Button>
    </Box>
  );
};

export default AccountResetPasswordForm;
